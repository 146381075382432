import React from "react";
import Sales from "./Sales";
import TotalSales from "./TotalSales";

export default function SalesRoiChart({setActiveMatics,setActiveSogas,activeMatics,activeSogas,salesRoi,salesFunnelOnly}) {
  return (
    <div className="pb-6 w-full">
      <div className="bg-white w-full">
        <div className="grid grid-cols-12">
          <div className="col-span-9 border-r p-6 flex flex-col">
            <div className="flex">
              <h4 className="text-[20px] text-black font-semibold">
                Sales & ROI
              </h4>
              <div className="ps-3 flex items-center">
                    <label
                      htmlFor="matics"
                      className="cursor-pointer flex items-center text-[15px] font-normal"
                    >
                      <input
                        id="matics"
                        type="checkbox"
                        className="hidden peer"
                        value={activeMatics}
                        checked={activeMatics}
                        onChange={() => setActiveMatics(!activeMatics)}
                      />
                      <span className="mr-2 peer-checked:bg-[#0080A6] peer-checked:border-[#0080A6] bg-white h-[16px] w-[16px] before:content-[''] relative before:absolute before:bottom-[5.55px] before:left-[2px] before:h-[5px] before:w-[10px] before:border-b-2 before:border-l-2 before:-rotate-[45deg] before:border-white inline-block border-[1.5px] border-[#ccc] rounded-sm"></span>
                      Matics
                    </label>
                  </div>
                  <div className="ps-3 flex items-center">
                    <label
                      htmlFor="sogas"
                      className="cursor-pointer flex items-center text-[15px] font-normal"
                    >
                      <input
                        id="sogas"
                        type="checkbox"
                        className="hidden peer"
                        value={activeSogas}
                        checked={activeSogas}
                        onChange={() => setActiveSogas(!activeSogas)}
                      />
                      <span className="mr-2 peer-checked:bg-[#0080A6] peer-checked:border-[#0080A6] bg-white h-[16px] w-[16px] before:content-[''] relative before:absolute before:bottom-[5.55px] before:left-[2px] before:h-[5px] before:w-[10px] before:border-b-2 before:border-l-2 before:-rotate-[45deg] before:border-white inline-block border-[1.5px] border-[#ccc] rounded-sm"></span>
                      Sogas
                    </label>
                  </div>

            </div>   
            
            <Sales salesRoi={salesRoi} salesFunnelOnly={salesFunnelOnly} />
          </div>
          <div className="col-span-3">
            <TotalSales salesRoi={salesRoi} salesFunnelOnly={salesFunnelOnly} />
          </div>
        </div>
      </div>
    </div>
  );
}
