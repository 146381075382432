import React, { useState }  from "react";
import Daily from "./Daily";
import Monthly from "./Monthly";
import moment from "moment";

export default function UsersChart({ dailyAccounts, monthlyAccounts,setSelectedLeague,setActiveMaticsDaual,setActiveSogasDaual,setCoinDaual,activeMaticsDaual,activeSogasDaual,activeCoinDaual }) {
  const [selectedLeague, setSelectedLeagued] = useState('all');

  const handleLeagueChange = (league) => {
    setSelectedLeague(league);
    setSelectedLeagued(league);
  };
  return (
    <div className="p-6 bg-white w-full mt-6">
      <div className="grid grid-cols-12 gap-5">
        <div className="col-span-9 border-r pr-5 flex flex-col">
        <div className="flex mb-6">
            <h4 className="text-[20px] text-black font-semibold mb-0">
              Daily Active Users 
              <span 
                className={`inline-block cursor-pointer mx-2 ${selectedLeague === 'all' ? 'text-black' : 'text-[#5468DB]'}`} 
                onClick={() => handleLeagueChange('all')}
              >
                All Leagues
              </span>
              | 
              <span 
                className={`inline-block cursor-pointer mx-2 ${selectedLeague === '1' ? 'text-black' : 'text-[#5468DB]'}`} 
                onClick={() => handleLeagueChange('1')}
              >
                Amateur
              </span>
              | 
              <span 
                className={`inline-block cursor-pointer mx-2 ${selectedLeague === '2' ? 'text-black' : 'text-[#5468DB]'}`} 
                onClick={() => handleLeagueChange('2')}
              >
                Pro
              </span>
              | 
              <span 
                className={`inline-block cursor-pointer mx-2 ${selectedLeague === '3' ? 'text-black' : 'text-[#5468DB]'}`} 
                onClick={() => handleLeagueChange('3')}
              >
                Stars
              </span>

            </h4>
            <div className="ps-3 flex items-center">
                <label
                  htmlFor="maticsDaual"
                  className="cursor-pointer flex items-center text-[15px] font-normal"
                >
                  <input
                    id="maticsDaual"
                    type="checkbox"
                    className="hidden peer"
                    value={activeMaticsDaual}
                    checked={activeMaticsDaual}
                    onChange={() => setActiveMaticsDaual(!activeMaticsDaual)}
                  />
                  <span className="mr-2 peer-checked:bg-[#0080A6] peer-checked:border-[#0080A6] bg-white h-[16px] w-[16px] before:content-[''] relative before:absolute before:bottom-[5.55px] before:left-[2px] before:h-[5px] before:w-[10px] before:border-b-2 before:border-l-2 before:-rotate-[45deg] before:border-white inline-block border-[1.5px] border-[#ccc] rounded-sm"></span>
                  Matics
                </label>
              </div>
              <div className="ps-3 flex items-center">
                <label
                  htmlFor="sogasDaual"
                  className="cursor-pointer flex items-center text-[15px] font-normal"
                >
                  <input
                    id="sogasDaual"
                    type="checkbox"
                    className="hidden peer"
                    value={activeSogasDaual}
                    checked={activeSogasDaual}
                    onChange={() => setActiveSogasDaual(!activeSogasDaual)}
                  />
                  <span className="mr-2 peer-checked:bg-[#0080A6] peer-checked:border-[#0080A6] bg-white h-[16px] w-[16px] before:content-[''] relative before:absolute before:bottom-[5.55px] before:left-[2px] before:h-[5px] before:w-[10px] before:border-b-2 before:border-l-2 before:-rotate-[45deg] before:border-white inline-block border-[1.5px] border-[#ccc] rounded-sm"></span>
                  Sogas
                </label>
              </div>
              <div className="ps-3 flex items-center">
                <label
                  htmlFor="coinsDaual"
                  className="cursor-pointer flex items-center text-[15px] font-normal"
                >
                  <input
                    id="coinsDaual"
                    type="checkbox"
                    className="hidden peer"
                    value={activeCoinDaual}
                    checked={activeCoinDaual}
                    onChange={() => setCoinDaual(!activeCoinDaual)}
                  />
                  <span className="mr-2 peer-checked:bg-[#0080A6] peer-checked:border-[#0080A6] bg-white h-[16px] w-[16px] before:content-[''] relative before:absolute before:bottom-[5.55px] before:left-[2px] before:h-[5px] before:w-[10px] before:border-b-2 before:border-l-2 before:-rotate-[45deg] before:border-white inline-block border-[1.5px] border-[#ccc] rounded-sm"></span>
                  in-game coin
                </label>
              </div>
          </div>
          
          <Daily dailyAccounts={dailyAccounts} />
        </div>
        <div className="col-span-3">
          <h4 className="text-[20px] text-black font-semibold mb-4">
            Monthly Active Users (Up to day {moment().format('Do')})
          </h4>
          <Monthly monthlyAccounts={monthlyAccounts} />
        </div>
      </div>
    </div>
  );
}
