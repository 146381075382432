import {
  Routes,
  Route,
  useLocation,
  useNavigate,
  Navigate,
} from "react-router-dom";
import { useEffect, useState } from "react";

import Sidebar from "../components/Sidebar";
import Header from "../components/Header";

// Auth routes
import LoginScreen from "../pages/Auth/Login";
import LoginRedirectScreen from "../pages/Auth/LoginToken";
import RegisterScreen from "../pages/Auth/Register";
import ResetPasswordScreen from "../pages/Auth/ResetPassword";
import { getUserToken } from "../api";

// General routes
import DashboardScreen from "../pages/Dashboard";
import MarketplaceSreen from "../pages/Marketplace";
import PlayerProgressScreen from "../pages/PlayerProgress";
import StreamerScreen from "../pages/Streamer";
import KolManagerScreen from "../pages/KolManager";
import TokenomicsScreen from "../pages/Tokenomics";
import TokenomicsSalesScreen from "../pages/TokenomicsSales";
import KOLsStatsScreen from "../pages/KOLsStats";
import SalesFunnelTokenomics from "../pages/SalesFunnel";
import NFTStatusScreen from "../pages/NFTStatus";
import ManagerScreen from "../pages/Manager";
import RentalHeroesScreen from "../pages/RentalHeroes";
import CirculantSupplyScreen from "../pages/CirculantSupply";
import NotFoundScreen from "../pages/NotFound";
import MissionManagerScreen from "../pages/MissionManager"

export default function AppRoutes() {
  const [mobile, setMobile] = useState(false);
  const [sidebar, setSidebar] = useState(false);
  const [route, setRoute] = useState("");
  const location = useLocation();

  const navigate = useNavigate();

  useEffect(() => {
    const isMatch = location.pathname.startsWith(
      "/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9"
    );

    const fetcUserToken = async () => {
      const token = location.pathname.substring(1);
      if (token && isMatch) {
        localStorage.setItem("token", token);
      }
      let localToken = localStorage.getItem("token");

      if (!localToken & !token) return;
      let status;
      if (localToken) {
        try {
          status = await getUserToken(localToken);
          let userInfo = JSON.stringify(status);
          localStorage.setItem("userData", userInfo);
        } catch (error) {
          console.log(error, "error");
        }
      }

      if (status && (status?.admin_permission_bin_code & 256) === 256) {
        navigate("/dashboard");
      } else if (status && (status?.admin_permission_bin_code & 1) === 1) {
        navigate("/streamer");
      } else {
        localStorage.clear();
        window.location.href = "https://www.socialgames.com/";
      }
    };
    fetcUserToken();
  }, []);

  useEffect(() => {
    setSidebarShow(location.pathname);
  }, [location.pathname]);

  const showList = [
    "/dashboard",
    "/streamer",
    "/kol-manager",
    "/kols-stats",
    "/tokenomics",
    "/nft-status",
    "/circulant-supply",
    "/player-progress",
    "/manager",
    "/rental-heroes",
    "/tokenomics-sales",
    "/marketplace",
    "/mission-manager",
    "/sales-funnel"
  ];

  const setSidebarShow = (param) => {
    if (showList.includes(param)) {
      setRoute(true);
    } else {
      setRoute(false);
    }
  };

  const toggleSidebar = () => {
    setSidebar(!sidebar);
  };
  const mobileToggler = () => {
    setMobile(!mobile);
    setSidebar(false);
  };
  return (
    <>
      {route && (
        <Sidebar
          mobile={mobile}
          sidebar={sidebar}
          toggleSidebar={toggleSidebar}
          mobileToggler={mobileToggler}
        />
      )}
      <div className="min-h-screen">
        <div
          className={`grow h-full transition-all duration-300 ${
            !route && "!pl-0"
          } ${!sidebar ? "md:pl-[250px]" : "md:pl-[70px]"}`}
        >
          {route && (
            <Header
              mobileToggler={mobileToggler}
              toggleSidebar={toggleSidebar}
            />
          )}
          <Routes>
            <Route path="/:token" element={<LoginRedirectScreen />} />
            <Route path="/" element={<LoginScreen />} />
            <Route path="/register" element={<RegisterScreen />} />
            <Route path="/reset-password" element={<ResetPasswordScreen />} />
            <Route path="/dashboard" element={<DashboardScreen />} />
            <Route path="/mission-manager" element={<MissionManagerScreen />} />
            <Route path="/kols-stats" element={<KOLsStatsScreen />} />
            <Route path="/sales-funnel" element={<SalesFunnelTokenomics />} />
            <Route path="/streamer" element={<StreamerScreen />} />
            <Route path="/kol-manager" element={<KolManagerScreen />} />
            <Route path="/player-progress" element={<PlayerProgressScreen />} />
            <Route path="/tokenomics" element={<TokenomicsScreen />} />
            <Route
              path="/tokenomics-sales"
              element={<TokenomicsSalesScreen />}
            />
            <Route path="/nft-status" element={<NFTStatusScreen />} />
            <Route path="/manager" element={<ManagerScreen />} />
            <Route path="/rental-heroes" element={<RentalHeroesScreen />} />
            <Route
              path="/circulant-supply"
              element={<CirculantSupplyScreen />}
            />
            <Route
              path="/marketplace"
              element={<MarketplaceSreen />}
            />

            <Route path="/404" element={<NotFoundScreen />} />
            {/* Default route for not found */}
            <Route path="*" element={<Navigate to="/404" />} />
          </Routes>
        </div>
      </div>
    </>
  );
}
