import moment from "moment";
import React from "react";
import {
  ComposedChart,
  Area,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

export default function Sales({ salesRoi,salesFunnelOnly }) {

  const roundToTwoDecimalPlaces = (input) => {
    const number = parseFloat(input);

    if (typeof number === "number" && !Number.isNaN(number) && number !== 0) {
      const roundedNumber = parseFloat(number.toFixed(2));
      return roundedNumber.toLocaleString();
    }

    if (isNaN(number)) {
      return 0;
    }

    return input;
  };
  let salesRoiData = salesRoi?.sales?.map((sales) => {
    return {
      ...sales,
      day: moment(sales.day).format("MMM YY"),
    };
  });
  //console.log(salesRoiData)
  let largestValue = Number.NEGATIVE_INFINITY;
  salesRoiData?.forEach((obj) => {
    Object.values(obj).forEach((value) => {
      if (typeof value === "number" && value > largestValue) {
        largestValue = value;
      }
    });
  });
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="p-4 bg-white border">
          <p className="mb-2">{label}</p>
          {payload.map((item, index) => (
            <p
              key={index}
              className={`text-[${item.color}] mb-2`}
            >
              {`${item.name}: ${roundToTwoDecimalPlaces(item.value).toLocaleString()}`}
            </p>
          ))}
        </div>
      );
    }

    return null;
  };

  const newArrayLength = salesRoiData?.length;
  const divisionFactor = largestValue / newArrayLength;
  let yTicks = Array(newArrayLength).fill(0);
  for (let i = 0; i < newArrayLength; i++) {
    if (i === 0) {
      yTicks[i] += Math.ceil(divisionFactor);
    } else {
      yTicks[i] = Math.floor(yTicks[i - 1] + divisionFactor);
    }
  }

  return (
    <div className="w-full h-[400px] my-auto">
      <ResponsiveContainer>
          <ComposedChart data={salesRoiData}>
            <XAxis dataKey="day" />
            <YAxis hide yAxisId={2} ticks={yTicks} />
            <YAxis hide yAxisId={3} ticks={yTicks} />
            <YAxis />
            <Tooltip content={<CustomTooltip />} />
            <Legend />

            <Area
              type="monotone"
              dataKey="streamer_cost"
              stackId="2"
              stroke="#FFDA69"
              fill="#FFDA69"
              yAxisId={3}
            />
            <Area
              type="monotone"
              dataKey="cost_alert"
              stackId="2"
              stroke="#FF6969"
              fill="#FF6969"
              yAxisId={2}
            />
            <Bar dataKey="heroes_income" stackId="5" fill="#5468DB" />
            <Bar dataKey="vpls_income" stackId="5" fill="#4BC18F" />
            <Bar dataKey="supermarket_income" stackId="5" fill="#FFBB44" />
            <Bar dataKey="marketplace_income" stackId="5" fill="#0e1fff" />
            <Bar dataKey="rentals_income" stackId="5" fill="#9A00E9" />
            <Bar dataKey="tournaments_income" stackId="5" fill="#000" />
            <Bar dataKey="rest_of_worker_income" stackId="5" fill="#ccc" />
            
          </ComposedChart>
        </ResponsiveContainer>
      {/* {salesFunnelOnly ? (
        <ResponsiveContainer>
          <ComposedChart data={salesRoiData}>
            <XAxis dataKey="day" />
            <YAxis hide yAxisId={2} ticks={yTicks} />
            <YAxis hide yAxisId={3} ticks={yTicks} />
            <YAxis />
            <Tooltip content={<CustomTooltip />} />
            <Legend />

            <Area
              type="monotone"
              dataKey="streamer_cost"
              stackId="2"
              stroke="#FFDA69"
              fill="#FFDA69"
              yAxisId={3}
            />
            <Area
              type="monotone"
              dataKey="cost_alert"
              stackId="2"
              stroke="#FF6969"
              fill="#FF6969"
              yAxisId={2}
            />
            <Bar dataKey="heroes_income" stackId="5" fill="#5468DB" />
            <Bar dataKey="vpls_income" stackId="5" fill="#4BC18F" />
            <Bar dataKey="supermarket_income" stackId="5" fill="#FFBB44" />
            <Bar dataKey="marketplace_income" stackId="5" fill="#0e1fff" />
            <Bar dataKey="rentals_income" stackId="5" fill="#9A00E9" />
            <Bar dataKey="tournaments_income" stackId="5" fill="#000" />
            <Bar dataKey="rest_of_worker_income" stackId="5" fill="#ccc" />
            
          </ComposedChart>
        </ResponsiveContainer>
      ) : (
        <ResponsiveContainer>
          <ComposedChart data={salesRoiData}>
            <XAxis dataKey="day" />
            <YAxis yAxisId={2} ticks={yTicks} />
            <YAxis hide yAxisId={3} ticks={yTicks} />
            <YAxis hide yAxisId={4} ticks={yTicks} />
            <YAxis hide yAxisId={5} ticks={yTicks} />
            <YAxis hide />
            <Tooltip content={<CustomTooltip />} />
            <Legend />

        

            <Area
              type="monotone"
              dataKey="streamer_cost"
              stackId="2"
              stroke="#FFDA69"
              fill="#FFDA69"
              yAxisId={3}
            />

            <Area
              type="monotone"
              dataKey="cost_alert"
              stackId="2"
              stroke="#FF6969"
              fill="#FF6969"
              yAxisId={2}
            />

            <Bar yAxisId={5} dataKey="heroes_income" stackId="a" fill="#5468DB" />
            <Bar yAxisId={5} dataKey="vpls_income" stackId="a" fill="#4BC18F" />
            <Bar
              yAxisId={5}
              dataKey="supermarket_income"
              stackId="a"
              fill="#FFBB44"
            />
            <Bar
              yAxisId={5}
              dataKey="marketplace_income"
              stackId="a"
              fill="#0e1fff"
            />
            <Bar
              yAxisId={5}
              dataKey="rentals_income"
              stackId="a"
              fill="#9A00E9"
            />
            <Bar
              yAxisId={5}
              dataKey="tournaments_income"
              stackId="a"
              fill="#000"
            />
            <Bar dataKey="rest_of_worker_income" yAxisId={5} stackId="a" fill="#ccc" />
          </ComposedChart>
        </ResponsiveContainer>
      )} */}

    </div>
  );
}
