import SalesFunnelTable from "../../components/SalesFunnelTable";
import SalesRoiChart from "../../components/SalesRoiChart";
import { useEffect, useState } from "react";
import { getSalesFunnelTokenomics, getSalesROITokenomics,getSalesFunnelStatsTokenomics } from "../../api";
import moment from "moment";
import { BiLoaderCircle } from "react-icons/bi";
import ListBoxSelect from "./components/ListBoxSelect";

export default function KOLsStats() {
  const [salesFunnel, setSalesFunnel] = useState([]);
  const [salesRoi, setSalesRoi] = useState([]);
  const [loader, setLoader] = useState(false);
  const [activeMatics,setActiveMatics] = useState(true);
  const [activeSogas,setActiveSogas] = useState(true);
  const [from, setFrom] = useState(
    moment().subtract(1, "months").format("YYYY-MM-DD")
  );
  const [source, setSource] = useState("");
  const [referralVal, setReferralVal] = useState({ title: "No filter", value: "all" });
  
  const [to, setTo] = useState(moment().format("YYYY-MM-DD"));
  const [statsSalesFunnel,setStatsSalesFunnel] = useState(null);

  const [activeAccummulatedAccount,setActiveAccummulatedAccount] = useState(true);
  const [activeMaticsKols,setActiveMaticsKols] = useState(true);
  const [activeSogasKols,setActiveSogasKols] = useState(true);

  const sourceList = [
    { title: "Any source", value: "" },
    { title: "Steam", value: "1" },
    { title: "Epic games", value: "9" },
    { title: "Any marketplace", value: "999" },
  ];
  const referalList = [
    { title: "No filter", value: "all" },
    { title: "Any referral", value: "any_referred" },
    { title: "Referred by KOL", value: "kol_referred" },
    { title: "Referred by player", value: "player_referred" },
    { title: "No referred", value: "no_referred" },
  ];

  useEffect(() => {
    fetchSalesFunnel();
    fetchSalesROI();
    fetchSalesFunnelStats();
  }, [activeMatics,activeSogas]);

  const fetchSalesFunnelStats = async (code) => {
    try {
      let data = await getSalesFunnelStatsTokenomics(from, to, source, referralVal.value,activeAccummulatedAccount,activeMaticsKols,activeSogasKols);
      setStatsSalesFunnel(data);
    } catch (error) {
      console.error(error);
    }
  };
  const fetchSalesFunnel = async (code) => {
    try {
      let data = await getSalesFunnelTokenomics(from, to, source, referralVal.value,activeAccummulatedAccount,activeMaticsKols,activeSogasKols);
      setSalesFunnel(data);
    } catch (error) {
      console.error(error);
    }
  };
  const fetchSalesROI = async (code) => {
    try {
      let data = await getSalesROITokenomics(from, to, source, referralVal.value,activeMatics,activeSogas,activeAccummulatedAccount);
      setSalesRoi(data);
    } catch (error) {
      console.error(error);
    }
  };

  const filter = async () => {
    setLoader(true);
    await fetchSalesFunnel();
    await fetchSalesROI();
    await fetchSalesFunnelStats();
    setLoader(false);
  };
  return (
    <div className="flex flex-col items-center h-full p-6">
      <div className="p-6 rounded-[2px] border bg-white w-full mb-6">
        <p className="text-[16px] font-medium text-black">
          Select the date and reload the dashboard
        </p>
        <p className="text-[16px] font-medium text-black">
          [Current month]{" "}
          <span className="text-[#5468DB]">
            [Same period int the last month] [last 30 days] [last 60 days] [last
            90 days]
          </span>
        </p>
        <div className="flex items-center gap-5 mt-2">
          <label>From date</label>
          <div className="relative">
            <input
              type="text"
              value={from}
              onChange={(e) => setFrom(e.target.value)}
              className="h-[40px] w-[120px] px-3 border border-[#E8E7E4] placeholder:text-white"
            />
            <input
              type="date"
              value={from}
              onChange={(e) => setFrom(e.target.value)}
              className="absolute inset-0 opacity-0 z-1"
            />
          </div>
          <label>to date</label>
          <div className="relative">
            <input
              type="text"
              value={to}
              onChange={(e) => setTo(e.target.value)}
              className="h-[40px] w-[120px] px-3 border border-[#E8E7E4] placeholder:text-white"
            />
            <input
              type="date"
              value={to}
              onChange={(e) => setTo(e.target.value)}
              className="absolute inset-0 opacity-0 z-1"
            />
          </div>
          <div className="w-[150px]">
            <ListBoxSelect
              options={sourceList}
              selected={source}
              setSelected={setSource}
              placeholder={"Any source"}
            />
          </div>
          <div className="w-[150px]">
            <ListBoxSelect
              options={referalList}
              selected={referralVal}
              setSelected={setReferralVal}
              placeholder={"Any referral"}
            />
          </div>
          <div className="h-[40px] px-3 border border-[#E8E7E4] flex items-center">
            <label
              htmlFor="accummulated"
              className="cursor-pointer flex items-center text-[15px] font-normal"
            >
              <input
                id="accummulated"
                type="checkbox"
                className="hidden peer"
                value={activeAccummulatedAccount}
                checked={activeAccummulatedAccount}
                onChange={() => setActiveAccummulatedAccount(!activeAccummulatedAccount)}
              />
              <span className="mr-3 peer-checked:bg-[#0080A6] peer-checked:border-[#0080A6] bg-white h-[16px] w-[16px] before:content-[''] relative before:absolute before:bottom-[5.55px] before:left-[2px] before:h-[5px] before:w-[10px] before:border-b-2 before:border-l-2 before:-rotate-[45deg] before:border-white inline-block border-[1.5px] border-[#ccc] rounded-sm"></span>
              KPIS with accummulated accounts
            </label>
          </div>
          <div className="ps-3 flex items-center">
                    <label
                      htmlFor="maticsKols"
                      className="cursor-pointer flex items-center text-[15px] font-normal"
                    >
                      <input
                        id="maticsKols"
                        type="checkbox"
                        className="hidden peer"
                        value={activeMaticsKols}
                        checked={activeMaticsKols}
                        onChange={() => setActiveMaticsKols(!activeMaticsKols)}
                      />
                      <span className="mr-2 peer-checked:bg-[#0080A6] peer-checked:border-[#0080A6] bg-white h-[16px] w-[16px] before:content-[''] relative before:absolute before:bottom-[5.55px] before:left-[2px] before:h-[5px] before:w-[10px] before:border-b-2 before:border-l-2 before:-rotate-[45deg] before:border-white inline-block border-[1.5px] border-[#ccc] rounded-sm"></span>
                      Matics
                    </label>
                  </div>
                  <div className="ps-3 flex items-center">
                    <label
                      htmlFor="sogasKols"
                      className="cursor-pointer flex items-center text-[15px] font-normal"
                    >
                      <input
                        id="sogasKols"
                        type="checkbox"
                        className="hidden peer"
                        value={activeSogasKols}
                        checked={activeSogasKols}
                        onChange={() => setActiveSogasKols(!activeSogasKols)}
                      />
                      <span className="mr-2 peer-checked:bg-[#0080A6] peer-checked:border-[#0080A6] bg-white h-[16px] w-[16px] before:content-[''] relative before:absolute before:bottom-[5.55px] before:left-[2px] before:h-[5px] before:w-[10px] before:border-b-2 before:border-l-2 before:-rotate-[45deg] before:border-white inline-block border-[1.5px] border-[#ccc] rounded-sm"></span>
                      Sogas
                    </label>
                  </div>
          <button
            onClick={filter}
            className="h-[40px] px-8 text-[16px] text-white flex items-center justify-center bg-[#0080A6]"
          >
            {loader ? (
              <BiLoaderCircle
                size={22}
                className="animate-[spin_3s_linear_infinite]"
              />
            ) : (
              "Reload"
            )}
          </button>
        </div>
      </div>
      <SalesFunnelTable statsSalesFunnel={statsSalesFunnel} salesFunnel={salesFunnel} />
      <SalesRoiChart setActiveMatics={setActiveMatics} setActiveSogas={setActiveSogas} activeMatics={activeMatics} activeSogas={activeSogas} salesRoi={salesRoi} salesFunnelOnly={true}/>
    </div>
  );
}
