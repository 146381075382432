import React, { useEffect } from "react";

const SalesTable = ({ statsSalesFunnel, salesFunnel }) => {
  const findPercentage = (value,type = "percentage") => {
    const highestValue = Math.max(
      salesFunnel?.sales?.new_accounts || 0,
      salesFunnel?.sales?.active_accounts || 0
    );
  
    if (!highestValue || isNaN(value)) {
      return 0; // Return 0 if the input is invalid
    }
  
    const percentage = Math.round((value / highestValue) * 100);
  
    if (type === "pixel") {
      return (percentage / 100) * 500; // Convert percentage to pixels
    }
  
    return percentage; // Default to percentage
  };
  const roundToTwoDecimalPlaces = (input) => {
    const number = parseFloat(input);

    if (typeof number === "number" && !Number.isNaN(number) && number !== 0) {
      const roundedNumber = parseFloat(number.toFixed(2));
      return roundedNumber.toLocaleString();
    }

    if (isNaN(number)) {
      return 0;
    }

    return input;
  };
  return (
    <div className="w-full mt-6 pb-6">
      <div className="bg-white w-full overflow-x-auto">
        <div className="grid grid-cols-12 gap-5">
          <div className="col-span-9 border-r pr-5 flex flex-col">
          <table className="w-full min-w-[800px]">
            <thead>
              <tr>
                <td className="uppercase p-[8px_15px_8px_30px] text-center font-medium text-[16px] whitespace-nowrap"></td>
                <td className="uppercase p-[8px_15px] text-center font-medium text-[16px] whitespace-nowrap">
                  Sales Funnel
                </td>
              </tr>
            </thead>
            <tbody>
            <tr>
                <td className="border-t p-[8px_15px_8px_30px] text-[16px] font-medium whitespace-nowrap">
                  Accummulated accounts
                </td>
                <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                  <div className="flex justify-center items-center gap-3">
                    <p className="text-[16px]">
                      {salesFunnel?.sales?.accumulated_accounts} users
                    </p>
                   
                  </div>
                </td>
              </tr>
              <tr>
                <td className="border-t p-[8px_15px_8px_30px] text-[16px] font-medium whitespace-nowrap">
                  New accounts
                </td>
                <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                  <div className="flex justify-center items-center gap-3">
                    <p className="text-[16px]">
                      {salesFunnel?.sales?.new_accounts} users
                    </p>
                    <div style={{width:findPercentage(salesFunnel?.sales?.new_accounts,'pixel') + "px",}} className="bg-[#FFBB44] h-[20px]"></div>  
                    <p className="text-[16px]">
                      {findPercentage(salesFunnel?.sales?.new_accounts)}%
                    </p>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="border-t p-[8px_15px_8px_30px] text-[16px] font-medium whitespace-nowrap">
                  Active accounts
                </td>
                <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                  <div className="flex justify-center items-center gap-3">
                    <p className="text-[16px]">
                      {salesFunnel?.sales?.active_accounts} users
                    </p>
                    <div style={{width:findPercentage(salesFunnel?.sales?.active_accounts,'pixel') + "px",}} className="bg-[#FFBB44] h-[20px]"></div>  
                    
                    <p className="text-[16px]">
                      {findPercentage(salesFunnel?.sales?.active_accounts)}%
                    </p>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="border-t p-[8px_15px_8px_30px] text-[16px] font-medium whitespace-nowrap">
                  Min. 1 challenges
                </td>
                <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                  <div className="flex justify-center items-center gap-3">
                    <p className="text-[16px]">
                      {salesFunnel?.sales?.min_1_challenges} users
                    </p>
                    <div style={{width:findPercentage(salesFunnel?.sales?.min_1_challenges,'pixel') + "px",}} className="bg-[#FFBB44] h-[20px]"></div>  
                    <p className="text-[16px]">
                      {findPercentage(salesFunnel?.sales?.min_1_challenges)}%
                    </p>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="border-t p-[8px_15px_8px_30px] text-[16px] font-medium whitespace-nowrap">
                  Min. 10 challenges
                </td>
                <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                  <div className="flex justify-center items-center gap-3">
                    <p className="text-[16px]">
                      {salesFunnel?.sales?.min_10_challenges} users
                    </p>
                    <div style={{width:findPercentage(salesFunnel?.sales?.min_10_challenges,'pixel') + "px",}} className="bg-[#FFBB44] h-[20px]"></div>  
                    <p className="text-[16px]">
                      {findPercentage(salesFunnel?.sales?.min_10_challenges)}%
                    </p>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="border-t p-[8px_15px_8px_30px] text-[16px] font-medium whitespace-nowrap">
                Min. 25 challenges
                </td>
                <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                  <div className="flex justify-center items-center gap-3">
                    <p className="text-[16px]">
                      {salesFunnel?.sales?.min_25_challenges} users
                    </p>
                    <div style={{width:findPercentage(salesFunnel?.sales?.min_25_challenges,'pixel') + "px",}} className="bg-[#FFBB44] h-[20px]"></div>  
                    <p className="text-[16px]">
                      {findPercentage(salesFunnel?.sales?.min_25_challenges)}%
                    </p>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="border-t p-[8px_15px_8px_30px] text-[16px] font-medium whitespace-nowrap">
                Min. 50 challenges
                </td>
                <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                  <div className="flex justify-center items-center gap-3" >
                    <p className="text-[16px]">
                      {salesFunnel?.sales?.min_50_challenges} users
                    </p>
                    <div style={{width:findPercentage(salesFunnel?.sales?.min_50_challenges,'pixel') + "px",}} className="bg-[#FFBB44] h-[20px]"></div>  
                    <p className="text-[16px]">
                      {findPercentage(salesFunnel?.sales?.min_50_challenges)}%
                    </p>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="border-t p-[8px_15px_8px_30px] text-[16px] font-medium whitespace-nowrap">
                  Borrow Hero
                </td>
                <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                  <div className="flex justify-center items-center gap-3">
                    <p className="text-[16px]">
                      {salesFunnel?.sales?.borrow_hero} users
                    </p>
                    <div style={{width:findPercentage(salesFunnel?.sales?.borrow_hero,'pixel') + "px",}} className="bg-[#FFBB44] h-[20px]"></div>  
                    <p className="text-[16px]">
                      {findPercentage(salesFunnel?.sales?.borrow_hero)}%
                    </p>
                  </div>
                </td>
              </tr>

              <tr>
                <td className="border-t p-[8px_15px_8px_30px] text-[16px] font-medium whitespace-nowrap">
                  Rent minimum 1 Hero
                </td>
                <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                  <div className="flex justify-center items-center gap-3">
                    <p className="text-[16px]">
                      {statsSalesFunnel?.sales?.rent_minimum_1_hero} users
                    </p>
                    <div style={{width:findPercentage(statsSalesFunnel?.sales?.rent_minimum_1_hero,'pixel') + "px",}} className="bg-[#FFBB44] h-[20px]"></div>  
                    <p className="text-[16px]">
                      {findPercentage(statsSalesFunnel?.sales?.rent_minimum_1_hero)}%
                    </p>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="border-t p-[8px_15px_8px_30px] text-[16px] font-medium whitespace-nowrap">
                  Rent minimum 5 Hero
                </td>
                <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                  <div className="flex justify-center items-center gap-3">
                    <p className="text-[16px]">
                      {statsSalesFunnel?.sales?.rent_minimum_5_hero} users
                    </p>
                    <div style={{width:findPercentage(statsSalesFunnel?.sales?.rent_minimum_5_hero,'pixel') + "px",}} className="bg-[#FFBB44] h-[20px]"></div>  
                    <p className="text-[16px]">
                      {findPercentage(statsSalesFunnel?.sales?.rent_minimum_5_hero)}%
                    </p>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="border-t p-[8px_15px_8px_30px] text-[16px] font-medium whitespace-nowrap">
                  Participate in 1 Free tournmanet
                </td>
                <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                  <div className="flex justify-center items-center gap-3">
                    <p className="text-[16px]">
                      {statsSalesFunnel?.sales?.participate_in_1_free_tournament} users
                    </p>
                    <div style={{width:findPercentage(salesFunnel?.sales?.participate_in_1_free_tournament,'pixel') + "px",}} className="bg-[#FFBB44] h-[20px]"></div>  
                    <p className="text-[16px]">
                      {findPercentage(salesFunnel?.sales?.participate_in_1_free_tournament)}%
                    </p>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="border-t p-[8px_15px_8px_30px] text-[16px] font-medium whitespace-nowrap">
                  Participate in 1 Paid tournmanet
                </td>
                <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                  <div className="flex justify-center items-center gap-3">
                    <p className="text-[16px]">
                      {statsSalesFunnel?.sales?.participate_in_1_paid_tournament} users
                    </p>
                    <div style={{width:findPercentage(salesFunnel?.sales?.participate_in_1_paid_tournament,'pixel') + "px",}} className="bg-[#FFBB44] h-[20px]"></div>  
                    <p className="text-[16px]">
                      {findPercentage(salesFunnel?.sales?.participate_in_1_paid_tournament)}%
                    </p>
                  </div>
                </td>
              </tr>

              <tr>
                <td className="border-t p-[8px_15px_8px_30px] text-[16px] font-medium whitespace-nowrap">
                Min. 1 purch. with ingame coin in marketplace
                </td>
                <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                  <div className="flex justify-center items-center gap-3">
                    <p className="text-[16px]">
                      {statsSalesFunnel?.sales?.min_1_purchase_with_ingame_coin_marketplace} users
                    </p>
                    <div style={{width:findPercentage(statsSalesFunnel?.sales?.min_1_purchase_with_ingame_coin_marketplace,'pixel') + "px",}} className="bg-[#FFBB44] h-[20px]"></div>  
                    <p className="text-[16px]">
                      {findPercentage(statsSalesFunnel?.sales?.min_1_purchase_with_ingame_coin_marketplace)}%
                    </p>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="border-t p-[8px_15px_8px_30px] text-[16px] font-medium whitespace-nowrap">
                Min. 1 purch. in marketplace (with matic, soga) 
                </td>
                <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                  <div className="flex justify-center items-center gap-3">
                    <p className="text-[16px]">
                      {statsSalesFunnel?.sales?.min_1_purchase_with_crypto_marketplace} users
                    </p>
                    <div style={{width:findPercentage(statsSalesFunnel?.sales?.min_1_purchase_with_crypto_marketplace,'pixel') + "px",}} className="bg-[#FFBB44] h-[20px]"></div>  
                    <p className="text-[16px]">
                      {findPercentage(statsSalesFunnel?.sales?.min_1_purchase_with_crypto_marketplace)}%
                    </p>
                  </div>
                </td>
              </tr>

              <tr>
                <td className="border-t p-[8px_15px_8px_30px] text-[16px] font-medium whitespace-nowrap">
                  Min. 1 purch. with ingame coin in supermarket
                </td>
                <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                  <div className="flex justify-center items-center gap-3">
                    <p className="text-[16px]">
                      {statsSalesFunnel?.sales?.min_1_purchase_with_ingame_coin_supermarket} users
                    </p>
                    <div style={{width:findPercentage(statsSalesFunnel?.sales?.min_1_purchase_with_ingame_coin_supermarket,'pixel') + "px",}} className="bg-[#FFBB44] h-[20px]"></div>  
                    <p className="text-[16px]">
                      {findPercentage(statsSalesFunnel?.sales?.min_1_purchase_with_ingame_coin_supermarket)}%
                    </p>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="border-t p-[8px_15px_8px_30px] text-[16px] font-medium whitespace-nowrap">
                Min. 1 purch. in supermarket (with matic, soga) 
                </td>
                <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                  <div className="flex justify-center items-center gap-3">
                    <p className="text-[16px]">
                      {statsSalesFunnel?.sales?.min_1_purchase_with_crypto_supermarket} users
                    </p>
                    <div style={{width:findPercentage(statsSalesFunnel?.sales?.min_1_purchase_with_crypto_supermarket,'pixel') + "px",}} className="bg-[#FFBB44] h-[20px]"></div>  
                    <p className="text-[16px]">
                      {findPercentage(statsSalesFunnel?.sales?.min_1_purchase_with_crypto_supermarket)}%
                    </p>
                  </div>
                </td>
              </tr>

              
              <tr>
                <td className="border-t p-[8px_15px_8px_30px] text-[16px] font-medium whitespace-nowrap">
                  Purch min 1 Hero
                </td>
                <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                  <div className="flex justify-center items-center gap-3">
                    <p className="text-[16px]">
                      {salesFunnel?.sales?.puchased_min_1_hero} users
                    </p>
                    <div style={{width:findPercentage(salesFunnel?.sales?.puchased_min_1_hero,'pixel') + "px",}} className="bg-[#FFBB44] h-[20px]"></div>  
                    <p className="text-[16px]">
                      {findPercentage(salesFunnel?.sales?.puchased_min_1_hero)}%
                    </p>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="border-t p-[8px_15px_8px_30px] text-[16px] font-medium whitespace-nowrap">
                  Purch min 2 Heroes
                </td>
                <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                  <div className="flex justify-center items-center gap-3">
                    <p className="text-[16px]">
                      {salesFunnel?.sales?.puchased_min_2_heroes} users
                    </p>
                    <div style={{width:findPercentage(salesFunnel?.sales?.puchased_min_2_heroes,'pixel') + "px",}} className="bg-[#FFBB44] h-[20px]"></div>  
                    <p className="text-[16px]">
                      {findPercentage(salesFunnel?.sales?.puchased_min_2_heroes)}%
                    </p>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="border-t p-[8px_15px_8px_30px] text-[16px] font-medium whitespace-nowrap">
                  Purch min 3 Heroes
                </td>
                <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                  <div className="flex justify-center items-center gap-3">
                    <p className="text-[16px]">
                      {salesFunnel?.sales?.puchased_min_3_heroes} users
                    </p>
                    <div style={{width:findPercentage(salesFunnel?.sales?.puchased_min_3_heroes,'pixel') + "px",}} className="bg-[#FFBB44] h-[20px]"></div>  
                    <p className="text-[16px]">
                      {findPercentage(salesFunnel?.sales?.puchased_min_3_heroes)}%
                    </p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          </div>  
          <div className="col-span-3">
            <table className="w-full">
              <thead>
                <tr>
                  <td className="uppercase p-[8px_15px] text-left font-medium text-[16px] whitespace-nowrap">
                    KPIS
                  </td>
                  <td className="uppercase p-[8px_15px] text-center font-medium text-[16px] whitespace-nowrap"></td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border-t p-[8px_15px] text-[16px] font-medium text-left whitespace-nowrap">
                    CAC1 - New account
                  </td>
                  <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                    {salesFunnel?.kpis?.cac1_new_account > 0
                      ? "$" +
                        roundToTwoDecimalPlaces(salesFunnel?.kpis?.cac1_new_account)
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <td className="border-t p-[8px_15px] text-[16px] font-medium text-left whitespace-nowrap">
                    CAC2 - Playing accounts
                  </td>
                  <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                    {salesFunnel?.kpis?.cac2_active_account > 0
                      ? "$" + salesFunnel?.kpis?.cac2_active_account
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <td className="border-t p-[8px_15px] text-[16px] font-medium text-left whitespace-nowrap">
                    CAC3 - Paying accounts
                  </td>
                  <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                    {salesFunnel?.kpis?.cac3_paying_account > 0
                      ? "$" +
                        roundToTwoDecimalPlaces(
                          salesFunnel?.kpis?.cac3_paying_account
                        )
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <td className="border-t p-[8px_15px] text-[16px] font-medium text-left whitespace-nowrap">
                    ARPPU
                  </td>
                  <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                    {salesFunnel?.kpis?.arppu > 0
                      ? "$" + salesFunnel?.kpis?.arppu
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <td className="border-t p-[8px_15px] text-[16px] font-medium text-left whitespace-nowrap">
                    ARPU
                  </td>
                  <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                    {salesFunnel?.kpis?.arpu > 0
                      ? "$" + salesFunnel?.kpis?.arpu
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <td className="border-t p-[8px_15px] text-[16px] font-medium text-left whitespace-nowrap">
                    Investment
                  </td>
                  <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                    {salesFunnel?.kpis?.total_cost > 0
                      ? "$" + roundToTwoDecimalPlaces(salesFunnel?.kpis?.total_cost)
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <td className="border-t p-[8px_15px] text-[16px] font-medium text-left whitespace-nowrap">
                    Income
                  </td>
                  <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                    {salesFunnel?.kpis?.total_income > 0
                      ? "$" +
                        roundToTwoDecimalPlaces(salesFunnel?.kpis?.total_income)
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <td className="border-t p-[8px_15px] text-[16px] font-medium text-left whitespace-nowrap">
                    ROI
                  </td>
                  <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                    {salesFunnel?.kpis?.roi > 0 ? salesFunnel?.kpis?.roi : "-"}
                  </td>
                </tr>
                <tr>
                  <td className="border-t p-[8px_15px] text-[16px] font-medium text-left whitespace-nowrap">
                    Retention 1 day
                  </td>
                  <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                    {statsSalesFunnel?.kpis?.retension_1_days > 0 ? statsSalesFunnel?.kpis?.retension_1_days : "-"}
                  </td>
                </tr>
                <tr>
                  <td className="border-t p-[8px_15px] text-[16px] font-medium text-left whitespace-nowrap">
                    Retention 7 day
                  </td>
                  <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                    {statsSalesFunnel?.kpis?.retension_7_days > 0 ? statsSalesFunnel?.kpis?.retension_7_days : "-"}
                  </td>
                </tr>
                <tr>
                  <td className="border-t p-[8px_15px] text-[16px] font-medium text-left whitespace-nowrap">
                    Retention 30 days
                  </td>
                  <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                    {statsSalesFunnel?.kpis?.retension_30_days > 0
                      ? statsSalesFunnel?.kpis?.retension_30_days + "%"
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <td className="border-t p-[8px_15px] text-[16px] font-medium text-left whitespace-nowrap">
                    Retention 60 days
                  </td>
                  <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                    {statsSalesFunnel?.kpis?.retension_60_days > 0
                      ? statsSalesFunnel?.kpis?.retension_60_days + "%"
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <td className="border-t p-[8px_15px] text-[16px] font-medium text-left whitespace-nowrap">
                    Retention 90 days
                  </td>
                  <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                    {statsSalesFunnel?.kpis?.retension_90_days > 0
                      ? statsSalesFunnel?.kpis?.retension_90_days + "%"
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <td className="border-t p-[8px_15px] text-[16px] font-medium text-left whitespace-nowrap">
                    Customer Lifecycle
                  </td>
                  <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                    {statsSalesFunnel?.kpis?.customer_lifecycle > 0
                      ? statsSalesFunnel?.kpis?.customer_lifecycle + "%"
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <td className="border-t p-[8px_15px] text-[16px] font-medium text-left whitespace-nowrap">
                    Customer Lifecycle Sales
                  </td>
                  <td className="border-t p-[8px_15px] text-[16px] font-medium text-center whitespace-nowrap">
                    {statsSalesFunnel?.kpis?.customer_lifetime_sales > 0
                      ? statsSalesFunnel?.kpis?.customer_lifetime_sales + "%"
                      : "-"}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesTable;
