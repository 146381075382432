import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

export default function Income({ workersIncome, income }) {
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="p-4 bg-white border">
          <p className="mb-2">
            {label} {payload[0].payload.dayName}
          </p>
          <p className="text-[#5468DB] mb-2">{`Builder Income: ${payload[0].value} `}</p>
          <p className="text-[#4BC18F] mb-2">{`Sales Income: ${payload[1].value} `}</p>
          <p className="text-[#FFBB44] mb-2">{`Gym Income: ${payload[2]?.value}`}</p>
          <p className="text-[#E97300] mb-2">{`Engineering Income: ${payload[3]?.value}`}</p>
          <p className="text-[#9A00E9] mb-2">{`Officers Income: ${payload[4]?.value}`}</p>
          <p className="text-[#0e1fff] mb-2">{`Marketplace: ${payload[5]?.value}`}</p>
          <p className="text-[#00ff00] mb-2">{`Rental: ${payload[6]?.value}`}</p>
          <p className="text-[#000] mb-2">{`Tournaments: ${payload[7]?.value}`}</p>
        </div>
      );
    }

    return null;
  };
  return (
    <div className="h-[300px]">
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={workersIncome}
          margin={{
            top: 10,
            right: 0,
            left: 0,
            bottom: 0,
          }}
          barSize={20}
        >
          <XAxis dataKey="day" />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          <Bar
            dataKey={income ? "builder_income" : "builder_amount"}
            stackId="a"
            fill="#5468DB"
          />
          <Bar
            dataKey={income ? "sales_income" : "sales_amount"}
            stackId="a"
            fill="#4BC18F"
          />
          <Bar
            dataKey={income ? "gym_income" : "gym_amount"}
            stackId="a"
            fill="#FFBB44"
          />
          <Bar
            dataKey={income ? "engineering_income" : "engineering_amount"}
            stackId="a"
            fill="#E97300"
          />

          <Bar
            dataKey={income ? "officer_income" : "officer_amount"}
            stackId="a"
            fill="#9A00E9"
          />

          <Bar
            dataKey={income ? "marketplace_income" : "marketplace_amount"}
            stackId="a"
            fill="#0e1fff"
          />

          <Bar
            dataKey={income ? "rental_pvp_income" : "rental_pvp_amount"}
            stackId="a"
            fill="#00ff00"
          />

          <Bar
            dataKey={income ? "tournaments_income" : "tournaments_amount"}
            stackId="a"
            fill="#000"
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
